import { z } from "zod";

export enum ErrorResponses {
  DuplicateEntity = "DuplicateEntity",
  Error = "Error",
  Exception = "Exception",
  InProgress = "InProgress",
  InUse = "InUse",
  InvalidPage = "InvalidPage",
  NoChange = "NoChange",
  NoContent = "NoContent",
  NotFound = "NotFound",
  NotValid = "NotValid",
  Success = "Success",
}

export type ErrorResponse = {
  message: string;
  kind: DataStatus | ErrorResponses;
  exception: string | null;
  stackTrace: string | null;
  exceptionType: string;
  innerException: string | null;
  validationErrors: {
    [key: string]: string[];
  } | null;
};

export type PossibleErrorResponses = ErrorResponse | string | undefined | null | Error;

export const errorResponseSchema = z.object({
  message: z.string(),
  kind: z.nativeEnum(ErrorResponses),
  exception: z.string().nullable(),
});

export function isApiErrorResponse(value: unknown): value is ErrorResponse {
  const validation = errorResponseSchema.safeParse(value);
  return validation.success;
}

export type ValidatorObject = {
  check: (value: unknown) => boolean;
  message: string | (() => string);
};

export type Exception = {
  message?: string | null;
  data?: {
    [key: string]: unknown;
  } | null;
  innerException?: Exception;
  helpLink?: string | null;
  source?: string | null;
  hResult?: number;
  stackTrace?: string | null;
};

export type DataStatus =
  | "Success"
  | "NotValid"
  | "Exception"
  | "Error"
  | "InUse"
  | "NotFound"
  | "NoChange"
  | "NoContent"
  | "InProgress"
  | "InvalidPage"
  | "Duplicate";

export type DataResponse<T_ReturnData> = {
  data?: T_ReturnData;
  successful?: boolean;
  failed?: boolean;
  isInUse?: boolean;
  isNotFound?: boolean;
  failedValidation?: boolean;
  message?: string | null;
  exception?: Exception;
  status?: DataStatus;
  innerResponses?: DataResponse<T_ReturnData>[] | null;
};

export enum TaskListBulkEditType {
  Skill = "skill",
  WeekInterval = "weekInterval",
  Calculation = "calculationType",
}

export enum WorkpackStatus {
  unassigned = "unassigned",
  assigned = "assigned",
  pending = "pending",
  inProgress = "in-progress",
}
