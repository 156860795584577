import { type ComposerTranslation } from "vue-i18n";
import type { MaybeRefOrGetter } from "vue";
import { CalculateMethodOpts } from "~/connectables/taskListManagement";
import type { WorkpackItemDto, LookUpViewNumber } from "~/connectables/transactions";
import { isString, isArray } from "assertate";
import type { DepartmentDto } from "~/connectables/employeeManagement";
import { WorkpackStatus } from "~/utils/types.ts";

/*
 * Create a deep copy of a JS object
 */
export function vueStructuredClone<T>(value: MaybeRefOrGetter<T>) {
  return structuredClone(toRaw(unref(value))) as T;
}

/*
 * Currency
 */
export const createCurrencyFormatter = (locale: string) => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "USD",
  });
};

export const formatAsCurrency = (value = 0, locale = "en") => {
  const formatter = createCurrencyFormatter(locale);
  return formatter.format(value);
};

/*
 * Debounce - a timeout
 */
const createDebounce = () => {
  let timeout: ReturnType<typeof setTimeout>;
  return function (fnc: () => void, delayMs = 500) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fnc();
    }, delayMs || 500);
  };
};

export const debounce = createDebounce();

/*
 * Return static default arrays and objects
 */
export function getCertificationLengthList(t: ComposerTranslation) {
  return [
    {
      label: t("formFields.years1"),
      value: 365,
    },
    {
      label: t("formFields.years2"),
      value: 730,
    },
    {
      label: t("formFields.years3"),
      value: 1095,
    },
    {
      label: t("formFields.lifetimeCertification"),
      value: 9999,
    },
  ];
}

export function getCalculateMethodOpts(t: ComposerTranslation) {
  return [
    {
      label: t("formFields.masterSchedule"),
      value: CalculateMethodOpts.MasterSchedule,
    },
    {
      label: t("formFields.fromLastDone"),
      value: CalculateMethodOpts.LastDone,
    },
  ];
}

export function getDefaultWorkpackFilterObject(t: ComposerTranslation) {
  return { label: t("formFields.all"), value: "all" };
}

/*
 * Format certification length label for display
 */
export function formatCertificationLength(val: number, t: ComposerTranslation) {
  if (!val) return null;
  const opts = getCertificationLengthList(t);
  const currentOpt = opts.find((opt) => opt.value === val);
  if (currentOpt) return currentOpt.label;

  return `${val} ${t("formFields.days")}`;
}

/**
 * A generic interface for anything that has a skill string
 */
export interface HasSkillsString {
  skill: string;
}

/**
 * Return all skills as a comma separated string
 * @param listWithSkills - A list of items that have a `skills` property
 */
export function getAllSkills<T extends HasSkillsString = HasSkillsString>(listWithSkills: T[]) {
  if (!isArray(listWithSkills)) return "";
  const skills = listWithSkills?.reduce((acc: string[], row: T) => {
    let formattedSkill: string | null = null;
    if (row?.skill && row?.skill?.trim()?.length > 0) {
      formattedSkill = row.skill.trim();
    }

    if (formattedSkill === null) return acc;

    const found = acc.find((r) => r === formattedSkill);
    if (!found) {
      acc.push(formattedSkill);
    }
    return acc;
  }, []);
  return skills.join(", ");
}

/*
 * For form controls -
 * If no prop text, return fallback text
 */
export function checkPropText(propText: string, fallback: string) {
  if (propText.length > 0) return propText;
  return fallback;
}

/*
 * Utils that return what to display based on element state
 * getListClass - CSS for zebra striped lists
 */
export function getListClass(active: boolean, selected: boolean, index: number, fallback: string) {
  if (active || selected) return "bg-nblue-3 z-50";
  if (index % 2 === 0) return "bg-nblue-4";
  return fallback;
}

/*
 * Whether or not to show the critical icon in a workpack task row
 */
export function showCritical(el: WorkpackItemDto) {
  return !!el?.criticalEquipment;
}

/*
 * Get a name from a LookUpView using a number (e.g. a LineDto)
 */
export function getDisplayName(number: number, array: LookUpViewNumber[], fallback: number | string): string {
  const name = array?.find((t) => t.number === number)?.name;
  if (name) return name;
  return isString(fallback) ? fallback : fallback.toString();
}

/*
 * Get a department name from a department number.
 */
export function getDepartmentName(
  departmentNumber: number | null | undefined,
  departmentList: DepartmentDto[] | null | undefined,
) {
  if (!departmentNumber || !departmentList) return null;
  const department = departmentList?.find((item) => item?.number === departmentNumber);
  if (department === undefined) return null;
  return department.name;
}

/*
 * Get the default item in an array
 */
export function getDefaultNumber(array?: { default: boolean; number: number }[] | null) {
  if (!array || !isArray(array)) return null;
  const defaultItem = array.find((item) => item.default === true);
  if (defaultItem === undefined) return null;
  return defaultItem.number;
}

/*
 * Get translated label for workpack or workpack task status
 */
export function getStatusLabel(value: WorkpackStatus, t: ComposerTranslation) {
  switch (value) {
    case WorkpackStatus.unassigned:
      return t("tableColumns.unassigned");
    case WorkpackStatus.assigned:
      return t("tableColumns.assigned");
    case WorkpackStatus.pending:
      return t("tableColumns.pending");
    default:
      return t("tableColumns.inProgress");
  }
}

/**
 * An empty function to be used by calls we do not need to await.
 */
export const DO_NOTHING = () => {};
